.wrapper
	font-style: normal
	display: flex
	flex-direction: column
	align-items: flex-start
	gap: 0.5em

.tags
	display: flex
	flex-wrap: wrap
	gap: 0.5em

.content
	flex-grow: 1

.name
	font-weight: 700

.phone
	margin-top: 0.5em
	margin-bottom: 0.5em
