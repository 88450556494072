// ⚠️⚠️⚠️ DO NOT EDIT! ⚠️⚠️⚠️
// COMPONENT HAS BEEN MODIFIED IN NEW MONOREPO AND CHANGES HERE WILL BE LOST

.amount
	font-variant-numeric: tabular-nums
	letter-spacing: -.05em

	.type_decimal,
	.type_fraction
		opacity: .75
		font-size: .75em

	.type_currency
		letter-spacing: 0

.creditsSign
	display: inline-block
	position: relative

	&::after
		display: inline-block
		content: "|"
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		text-align: center
		transform: translateY(-.11em)
