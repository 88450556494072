@use "sass:math"

$size: 80

.wrapper
	display: inline-block
	position: relative
	width: 1em * math.div(80, $size)
	height: 1em * math.div(80, $size)
	opacity: 0
	transition: opacity ease-in-out .5s
	pointer-events: none

	&.visible
		opacity: 1

.wrapper div
	animation: wrapper 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
	transform-origin: 1em * math.div(40, $size) 1em * math.div(40, $size)

.wrapper div:after
	content: " "
	display: block
	position: absolute
	width: 1em * math.div(7, $size)
	height: 1em * math.div(7, $size)
	border-radius: 50%
	background: currentColor
	margin: -1em * math.div(4, $size) 0 0 -1em * math.div(4, $size)

.wrapper div:nth-child(1)
	animation-delay: -0.036s

.wrapper div:nth-child(1):after
	top: 1em * math.div(63, $size)
	left: 1em * math.div(63, $size)

.wrapper div:nth-child(2)
	animation-delay: -0.072s

.wrapper div:nth-child(2):after
	top: 1em * math.div(68, $size)
	left: 1em * math.div(56, $size)

.wrapper div:nth-child(3)
	animation-delay: -0.108s

.wrapper div:nth-child(3):after
	top: 1em * math.div(71, $size)
	left: 1em * math.div(48, $size)

.wrapper div:nth-child(4)
	animation-delay: -0.144s

.wrapper div:nth-child(4):after
	top: 1em * math.div(72, $size)
	left: 1em * math.div(40, $size)

.wrapper div:nth-child(5)
	animation-delay: -0.18s

.wrapper div:nth-child(5):after
	top: 1em * math.div(71, $size)
	left: 1em * math.div(32, $size)

.wrapper div:nth-child(6)
	animation-delay: -0.216s

.wrapper div:nth-child(6):after
	top: 1em * math.div(68, $size)
	left: 1em * math.div(24, $size)

.wrapper div:nth-child(7)
	animation-delay: -0.252s

.wrapper div:nth-child(7):after
	top: 1em * math.div(63, $size)
	left: 1em * math.div(17, $size)

.wrapper div:nth-child(8)
	animation-delay: -0.288s

.wrapper div:nth-child(8):after
	top: 1em * math.div(56, $size)
	left: 1em * math.div(12, $size)

@keyframes wrapper
	0%
		transform: rotate(0deg)

	100%
		transform: rotate(360deg)
