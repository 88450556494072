
@use 'common'

$breakpoint: "(min-width: #{common.$breakMobile})"

:export
	desktopBreakpoint: $breakpoint

.content
	background-color: var(--global-colors-light)
	padding: 1rem 1.125rem
	border-radius: .625rem
	box-shadow: 0 0 1.25rem 0 #0000001a
	z-index: 1

.button
	position: relative
	border: .0625rem solid var(--global-colors-offWhite-700)
	aspect-ratio: 1
	border-radius: 50%
	padding: .625rem
	background-color: var(--global-colors-offWhite-100)
	transition-property: background-color
	transition-duration: .2s
	transition-timing-function: ease-in-out
	&.is_active
		background-color: var(--global-colors-offWhite-700)
	&.is_selected
		border: .125rem solid var(--global-colors-dark)

.buttonIcon
	font-size: 1.5rem
	display: flex

.badge
	font-weight: 700

.groups
	display: flex
	flex-direction: column
	gap: .75rem
