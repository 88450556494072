.wrapper
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	max-height: 100vh
	max-height: 100dvh
	background-color: rgba(0, 0, 0, 0.5)
	backdrop-filter: blur(4px)
