.wrapper
	display: grid
	grid-template-columns: 1fr
	gap: 3rem

	@media (min-width: 50rem)
		grid-template-columns: repeat(3, 1fr)

.title
	text-transform: uppercase
	margin: 0 0 1.5rem
