.wrapper
	display: grid
	gap: 0.3em
	margin: 0

	&.is_multiColumn
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr))
		column-gap: 1em

.item
	display: grid
	grid-template-columns: 1.1em minmax(1.5em, min-content) 1fr
	column-gap: 0.25rem
	line-height: 1.28em

.icon
	aspect-ratio: 1 / 1
	position: relative

.quantity
	font-size: 0.875em

.name
	font-size: 1.125em
