.title
	font-size: 1.75em
	font-weight: 300
	line-height: 1.4
	margin-top: 1.6em

.text
	margin-top: 1em
	color: #4a4a4a

.edit,
.forgottenPassword
	padding: 0
	text-decoration: underline
	
	&:hover,
	&:focus
		text-decoration: none

.edit
	margin-left: 0.5em

.forgottenPassword
	margin-top: 2em

.form
	margin-top: 2.5em

.button
	margin-top: 1.5625em
