$breakMobile: 800px
$border-width: 1px
$border-color: #979797
$tabs-border-radius: 1rem
$title-line-height: 1.35
$image-width: 2.5rem
$arrow-size: 1.5rem

.list
	border: 1px
	padding-left: 0

.item
	--DetailedList-title-column-width: auto
	border: $border-width solid $border-color
	display: grid
	padding: 0.5em 1.5em
	gap: 10px 20px
	// align-items: center
	transition-property: background-color
	transition-duration: 0.3s

	&.view-layout-shortTitle
		--DetailedList-title-column-width: 15.625rem

	@media (min-width: $breakMobile)
		gap: 0 20px
		grid-template-columns: auto min-content
		border: $border-width solid $border-color

	.is_listWithCollapsibleItems
		@media (min-width: $breakMobile)
			grid-template-columns: var(--DetailedList-title-column-width) 1fr $arrow-size

	.is_withImages &
		grid-template-columns: $image-width auto
		@media (min-width: $breakMobile)
			grid-template-columns: $image-width var(--DetailedList-title-column-width) 1fr

	.is_listWithCollapsibleItems.is_withImages &
		grid-template-columns: $image-width auto $arrow-size
		@media (min-width: $breakMobile)
			grid-template-columns: $image-width var(--DetailedList-title-column-width) 1fr $arrow-size

	&.is_highlightedOnHover
		cursor: pointer
		&:hover
			background-color: rgba(#000000, 0.05)

	&:first-child
		border-top-left-radius: $tabs-border-radius
		border-top-right-radius: $tabs-border-radius

	&:last-child
		border-bottom-left-radius: $tabs-border-radius
		border-bottom-right-radius: $tabs-border-radius

	&:not(:first-child)
		margin-top: -1 * $border-width

		.secondaryContent
			width: 100%

	.list.is_nested &
		border-left-style: none
		border-right-style: none
		border-bottom-style: none
		border-radius: 0

.item.is_collapsibleContentOpen .item
	// border-color: #ffffff

.secondaryContent
	height: 100%

	@media not all and (min-width: $breakMobile)
		grid-column: 1 / -1

.collapsibleContent
	grid-column: 1 / -1

	@media (min-width: $breakMobile)
		grid-column: 2 / -1

	.is_withNestedDetailedList &
		grid-column: 1 / -1
		margin: 0 calc(-1.5em - .0625rem) calc(-0.5em - .0625rem)

		@media (min-width: $breakMobile)
			margin-top: 0.5em

.arrow
	display: flex
	align-items: center
	min-height: 3.375rem
	align-self: start
	font-size: $arrow-size
	transition-property: transform
	transition-duration: 0.2s
	color: var(--global-colors-dark, #000000)

	@media not all and (min-width: $breakMobile)
		grid-row: 1
		grid-column: 3

	.is_collapsibleContentOpen &
		transform: scaleY(-1)

.image
	position: relative
	grid-row: 1
	align-self: start
	height: 2 * ($title-line-height * 1.25em)

	@media (min-width: $breakMobile)
		height: 2 * ($title-line-height * 1.25em)

.info
	display: flex
	flex-direction: column
	justify-content: center

.title
	text-transform: uppercase
	margin: 0
	font-weight: 300
	line-height: 1.35
	font-size: 1.25em

.description
	margin-top: 0.4em
	font-weight: 200
	flex-grow: 1
