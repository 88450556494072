@use 'common'

$padding: 1rem
$breakMobile: 50rem

.overlay
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	max-height: 100vh
	max-height: 100dvh
	display: flex
	align-items: center
	justify-content: center
	z-index: 20
	&:global(.ReactModal__Overlay--after-open)
		opacity: 1

	&:global(.ReactModal__Overlay--before-close)
		transition-timing-function: ease-in
		transition-delay: .2s
		opacity: 0
		pointer-events: none

.content
	position: relative
	background-color: var(--global-colors-light)

	max-width: calc(100% - 2 * #{var(--global-page-horizontal-spacing)})
	width: 100%
	max-height: calc(100% - 2 * #{var(--global-page-vertical-spacing)})
	border-radius: 10px
	overflow-y: auto
	display: flex
	flex-direction: column
	transition-property: opacity, transform
	transition-duration: .3s
	transition-timing-function: ease-out
	opacity: 0
	transform: translateY(1em)

	.view_transparent &
		background-color: transparent
		border-radius: 0

	:global(.ReactModal__Overlay--after-open) &
		opacity: 1
		transform: translateY(0)

	:global(.ReactModal__Overlay--before-close) &
		transition-timing-function: ease-in
		opacity: 0

	@media (min-width: $breakMobile)
		width: auto
		min-width: 32rem

	&::before,
	&::after
		content: ""
		flex-grow: 1

.view_bordered
	border: solid 1px

.view_noPadding
	padding: 0

=alignBottom
	.content
		max-width: none
		margin-top: auto
		border-bottom-right-radius: 0
		border-bottom-left-radius: 0
		transform: translateY(3em)

	&:global(.ReactModal__Overlay--after-open) .content
		transform: translateY(0)

	&:global(.ReactModal__Overlay--before-close) .content
		transform: translateY(3em)

.overlay.view_align_desktop_bottom
	+alignBottom

=alignTop
	.content
		max-width: none
		margin-bottom: auto
		border-top-right-radius: 0
		border-top-left-radius: 0
		transform: translateY(-3em)

	&:global(.ReactModal__Overlay--after-open) .content
		transform: translateY(0)

	&:global(.ReactModal__Overlay--before-close) .content
		transform: translateY(-3em)

=alignRight
	.content
		position: absolute
		top: 0
		bottom: 0
		right: 0
		border-radius: 0
		max-height: none
		transform: translateX(3em)
		display: block
		max-width: 32rem
		&::before,
		&::after
			display: none
		@media not all and (min-width: $breakMobile)
			max-width: none

	&:global(.ReactModal__Overlay--after-open) .content
		transform: translateX(0)

	&:global(.ReactModal__Overlay--before-close) .content
		transform: translateX(3em)

.overlay.view_align_desktop_right
	+alignRight

@media screen and not (min-width: $breakMobile)
	.overlay.view_align_mobile_bottom
		+alignBottom

	.overlay.view_align_mobile_top
		+alignTop
