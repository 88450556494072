.wrapper
	--RadioMark-size: 1.5em
	position: relative
	display: inline-block
	width: var(--RadioMark-size)
	height: var(--RadioMark-size)
	border: 1px solid #979797
	flex-shrink: 0
	border-radius: 50%
	margin-right: 0.4em
	background-color: #ffffff
	line-height: 1

	&::before
		--RadioMark-gap: 0.31em
		content: ''
		position: absolute
		top: var(--RadioMark-gap)
		left: var(--RadioMark-gap)
		right: var(--RadioMark-gap)
		bottom: var(--RadioMark-gap)
		border-radius: 50%
		background-color: var(--global-colors-green-800, #39a400)
		opacity: 0
		transform: scale(0)
		transition-property: opacity, transform
		transition-duration: 0.3s

input[type='radio']:checked ~
	.wrapper::before, * .wrapper::before
		opacity: 1
		transform: none

.wrapper.view_forceChecked::before
	opacity: 1
	transform: none
