$breakDesktop: 75rem
$breakMobile: 50rem

.wrapper
	--Card-padding: 1.5em
	background-color: #FBFBFB
	border: 1px solid #D7D4CF

	@media (min-width: $breakMobile)
		border-radius: .625em
	@media (min-width: $breakDesktop)
		--Card-padding: 2em

	& + &
		margin-top: 1em

.header
	width: 100%
	min-height: 80px
	padding: 0.75em var(--Card-padding)
	display: flex
	gap: 0.5em
	flex-wrap: wrap
	justify-content: space-between
	align-items: stretch
	text-align: left

.headerLabel
	display: flex
	flex-grow: 1
	margin-left: 1em

.right
	display: flex
	justify-content: flex-end
	flex-grow: 1
	@media (min-width: $breakMobile)
		align-items: center

.toggle
	margin-left: 0.5em

	@media (min-width: 500px)
		margin-left: 2em

.toggleIcon
	display: flex
	color: var(--global-colors-gray-300)
	font-size: 1.75em

	&.is_open
		transform: scaleY(-1)

.title
	display: flex
	align-items: center
	padding-block: 0.25em
	margin-block: -0.25em
	font-size: (25em / 16)
	line-height: 1.3
	text-overflow: ellipsis
	overflow: hidden
	max-width: 100%

.content
	padding: var(--Card-padding)
