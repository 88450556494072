.title
	font-size: 1.5em
	font-weight: 300
	line-height: 1.4
	margin-top: 0

.text
	margin-top: 1.5625em
	margin-bottom: 0
	color: #4a4a4a

.link
	text-decoration: underline

	&:hover,
	&:focus
		text-decoration: none

.button
	margin-top: 1.5625em

.idpButtons
	text-align: center
	margin-top: 1em

.inputAutoFillHelper
	display: none
