.title
	font-size: 1.75em
	font-weight: 300
	line-height: 1.4
	margin-top: 1.6em

.text
	margin-top: 1em
	color: #4a4a4a

.edit
	padding: 0
	text-decoration: underline
	margin-left: 0.5em
	
	&:hover,
	&:focus
		text-decoration: none

.form
	margin-top: 2.5em

.button
	margin-top: 1.5625em
