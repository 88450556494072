@use 'common'

$breakpoint: "(min-width: #{common.$breakMobile})"

@keyframes fadeIn
	0%
		opacity: 0
	100%
		opacity: 1

:export
	desktopBreakpoint: $breakpoint

.button
	padding: 0
	&:focus-visible
		outline: none

.overlay
	pointer-events: none

.content
	position: relative
	animation-name: fadeIn
	animation-duration: .3s
