.title
	display: grid
	align-items: center
	grid-template-columns: min-content auto
	grid-gap: 0 0.5em
	gap: 0 0.5em
	text-align: left

	@media (min-width: 48rem)
		grid-template-columns: min-content auto auto

.date
	font-size: 1.5625rem

.productsCount
	display: flex
	padding: .125rem 0
	font-size: 1.375rem
	margin-right: 0.5rem
