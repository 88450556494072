.wrapper
	pointer-events: none
	position: fixed
	top: 10px
	left: 10px
	right: 10px
	display: flex
	justify-content: center
	z-index: 1

.content
	display: flex
	align-items: center
	column-gap: 0.3em
