$goodlokLight: #EDEAE5
$goodlokLightHover: #DBD7D2
$goodlokBgLight: #E2DFDA
$goodlokDark: #28282A
$goodlokRed: #ED3129
$goodlokBlue: #027AFF
$goodlokDarkHover: #1A1A1B
$black: #000000
$white: #FFFFFF
$green: #2E8B57
$green-light: #39a400

$border-color: #979797
$border-width: 1px
$focus-background-color: rgba(#000000, 0.03)
$sideMenu-width: 20rem

$transition-duration: 0.3s

$spacing: 4px

$padding: 16px

$containerWidth: 1190px

$tabs-border-radius: 1rem
$tabs-active-color: $green-light
$tabs-active-color-light: #d5edd6

$breakMobile: 800px
$breakDesktop: 1200px
$breakLarge: 1500px
$breakExtraLarge: 1900px

$border: 2px solid #28282A

$pageWidth: 2000px
$narrowPageWidth: 800px

$fontNeueVektorName: 'NeueVektor'
$fontNeueVektor: $fontNeueVektorName, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

$fwNormal: 400
$fwBold: 600

$imageBorderRadius: calc(5px + 1vw)

$imagePlaceholderColor: rgba($goodlokDark, .05)
