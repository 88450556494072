$-break: 44rem

:root
	--global-page-horizontal-spacing: 1.25rem
	--global-page-vertical-spacing: var(--global-page-horizontal-spacing)

	--global-colors-dark: #333333
	--global-colors-light: #FBFBFB

	--global-colors-dark-700: #656565

	// This is fallback value overridden by AppFrame
	--global-colors-brand: #5C75F9

	--global-colors-offWhite-100: #F6F6F6
	--global-colors-offWhite-200: #F0F0EF
	--global-colors-offWhite-300: #E6E5E3
	--global-colors-offWhite-700: #D7D4CF

	--global-colors-gray-300: #E1E1E1
	--global-colors-gray-500: #979797

	--global-colors-green-50: #E8F8E8
	--global-colors-green-100: #DAECD8
	--global-colors-green-300: #8DC6AB
	--global-colors-green-600: #2EA271
	--global-colors-green-800: #257654
	--global-colors-green-900: #257654

	--global-colors-red-50: #f7f0ef
	--global-colors-red-100: #EFE4E2
	--global-colors-red-300: #EFD3CE
	--global-colors-red-600: #D43C3C
	--global-colors-red-900: #B60505

	--global-colors-orange-100: #FCEECB
	--global-colors-orange-900: #948501

	@media (min-width: $-break)
		--global-page-horizontal-spacing: 2.5rem
