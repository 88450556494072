.wrapper
	display: flex
	justify-content: center
	flex-wrap: wrap
	font-size: 1.5em
	font-weight: 200

.gap,
.page
	padding: 0.5em

.page
	border: none
	background: transparent
	display: grid
	grid-template-rows: 0 auto

	.is_loading &
		opacity: 0.5

	&:hover
		text-decoration: underline

	&.is_current,
	&::before
		content: attr(data-page)
		color: #ed3129
		font-weight: 700

	&::before
		visibility: hidden
