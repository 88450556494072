.title
	font-size: 1.75em
	font-weight: 300
	line-height: 1.4
	margin-top: 1.6em

.text
	margin-top: 1em
	color: #4a4a4a

	& + &
		margin-top: 2.5em

.link
	text-decoration: underline

	&:hover,
	&:focus
		text-decoration: none

.edit
	padding: 0
	text-decoration: underline
	margin-left: 0.5em
	
	&:hover,
	&:focus
		text-decoration: none

.form
	margin-top: 2.5em

.formRow
	& + &
		margin-top: 1em

.buttons
	display: flex
	align-items: center
	flex-direction: column
	gap: 1.3125em
	margin-top: 1.5625em

.useDifferentAccount
	padding: 0
	margin-top: 1em
	text-decoration: underline

	&:hover,
	&:focus
		text-decoration: none
