@use 'common'

.wrapper
	position: relative
	display: inline-flex
	background: #F8E8EC
	border-radius: .625rem
	padding: .3125rem

.icon
	font-size: 2em
	display: flex
