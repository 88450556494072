.wrapper
	--Switch-active-color: #39a400
	--Switch-active-color-light: #d5edd6
	--Switch-border-width: 1px
	--Switch-border-radius: 1rem
	--Switch-border-color: #979797
	--Switch-focus-background-color: rgba(0, 0, 0, 0.03)
	margin: 0
	padding: 0
	display: flex
	flex-direction: column
	list-style-type: none

@media (min-width: 50rem)
	.wrapper
		flex-direction: row

.item
	position: relative
	display: flex
	padding: 0

	&:first-child > *
		border-top-left-radius: var(--Switch-border-radius)
		border-top-right-radius: var(--Switch-border-radius)

	&:last-child > *
		border-bottom-left-radius: var(--Switch-border-radius)
		border-bottom-right-radius: var(--Switch-border-radius)

	&:not(:first-child)
		margin-top: calc(-1 * var(--Switch-border-width))

@media (min-width: 50rem)
	.item
		&:first-child > *
			border-top-right-radius: 0
			border-bottom-left-radius: var(--Switch-border-radius)

		&:last-child > *
			border-top-right-radius: var(--Switch-border-radius)

		&:not(:first-child)
			margin-top: 0
			margin-left: calc(-1 * var(--Switch-border-width))

			&:last-child > *
				border-bottom-left-radius: 0

.content
	padding: 1.25em
	display: flex
	align-items: center
	border-radius: inherit
	flex-grow: 1
	line-height: 1.1
	text-transform: uppercase
	border: var(--Switch-border-width) solid var(--Switch-border-color)

	.input:disabled + &
		opacity: 0.5

@media (min-width: 50rem)
	.content:not(.is_narrow)
		padding-right: 2em

.text
	font-size: 1.125em

.view_normalCasing .text
	text-transform: none

.label, .otherAction
	display: flex
	align-items: stretch
	flex-grow: 1
	cursor: pointer

.label,
.otherAction
	&:hover .input:not(:disabled) + .content
		background-color: var(--Switch-focus-background-color)

.otherAction
	margin: 0
	padding: 0
	font-size: inherit
	font-weight: inherit
	background-color: transparent
	border: none

.plus
	--Switch-plus-width: 0.09em
	width: 1em
	height: 1em
	position: relative
	margin: 0 auto

	&::before
		content: ''
		position: absolute
		top: 0
		left: calc(50% - var(--Switch-plus-width) / 2)
		bottom: 0
		width: var(--Switch-plus-width)
		background-color: currentColor

	&::after
		content: ''
		position: absolute
		top: 0
		left: calc(50% - var(--Switch-plus-width) / 2)
		bottom: 0
		width: var(--Switch-plus-width)
		background-color: currentColor
		top: calc(50% - var(--Switch-plus-width) / 2)
		left: 0
		bottom: auto
		right: 0
		width: 100%
		height: var(--Switch-plus-width)

@media (min-width: 50rem)
	.plus
		margin: 0

.input
	position: absolute
	pointer-events: none
	overflow: hidden
	width: 1px
	height: 1px
	opacity: 0
	margin: 0

	&:checked ~ .content
		background-color: var(--Switch-active-color-light)

.item.view_forceChecked .content
	background-color: var(--Switch-active-color-light)

.input:focus
	&:not(:checked) ~ .content
		background-color: var(--Switch-focus-background-color)

	&:focus-visible ~ .content
		outline: 5px auto Highlight
		outline: 5px auto -webkit-focus-ring-color

.customInput
	flex-grow: 1
	display: flex
	align-items: center
	gap: 0.5em

	.text:first-child
		margin-right: 0.5em
