@use './_common'

@keyframes bounceIn
	0%
		opacity: 0
		transform: scale(0.3) translate3d(0,0,0)

	50%
		opacity: 0.9
		transform: scale(1.1)

	80%
		opacity: 1
		transform: scale(0.89)

	100%
		opacity: 1
		transform: scale(1) translate3d(0,0,0)

.wrapper
	max-width: 40em
	margin: 0 auto

.cardContent
	text-align: center
	max-width: 30em
	margin: 0 auto
	padding: 2em 0

.title
	text-transform: uppercase
	color: var(--global-colors-green-900)

.icon
	opacity: 0
	display: inline-block
	animation: bounceIn .6s
	animation-delay: 1s
	animation-fill-mode: forwards

.notes
	margin: 2em auto
	max-width: 23em

	p
		line-height: 1.5
