.wrapper
	position: fixed
	inset: 0 0 auto
	z-index: 21 // above modal

	&.is_nested
		position: absolute
		z-index: 10 // below modal

.in
	position: absolute
	top: 0
	left: 0
	right: 0
	transform: translateY(-100%)
	transition-property: visibility, transform
	transition-duration: 0.2s
	visibility: hidden

@media (prefers-reduced-motion: reduce)
	.in
		transition-duration: 1ms

.wrapper.is_loading .in
	transform: none
	visibility: inherit
