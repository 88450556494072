.wrapper
	position: relative
	height: 0.3125em
	background-color: var(--global-colors-brand)
	overflow: hidden

	&::before
		content: ''
		position: absolute
		inset: 0
		background-color: rgba(255, 255, 255, 0.4)
		animation: LoadingBar-wipe 1s infinite

@keyframes LoadingBar-wipe
	0%
		transform: translateX(-100%)

	100%
		transform: translateX(100%)
