.wrapper
	margin: 0 auto
	padding: 1em
	width: 100%
	max-width: 32em
	text-align: left

	.logo
		margin-bottom: 1em

	&.view_webview
		.logo
			display: none

		h2
			margin-top: 0
			margin-bottom: .5em
