@use 'sass:math'

/* @TODO: unify with Input.module.sass

$text-offset-right-left: 1em

.wrapper
	position: relative
	display: grid
	gap: 0.8125em
	cursor: pointer

.label
	font-size: 1.25em
	line-height: 1.2
	font-weight: 300

.input
	$font-size: 1.125em
	$height: 2.8em
	$border-width: 1px
	display: block
	width: 100%
	font-size: 1.125em
	height: $height
	padding: calc(math.div($height - $font-size, 2) - 2 * $border-width) $text-offset-right-left
	border: $border-width solid #979797
	background-color: #ffffff
	border-radius: 0.28em

	// padding-right: 0.9em;
	box-sizing: border-box
	position: relative
	appearance: none
	padding-right: 1.5em

.is_labelPosition_inside
	.input
		height: 3.44em
		padding-top: 1.5em

	.label
		position: absolute
		top: 1.75em
		left: 0
		width: 100%
		display: flex
		align-items: center
		pointer-events: none
		font-size: 1.125em
		padding-right: $text-offset-right-left
		padding-left: $text-offset-right-left
		color: #28282a
		transform: scale(0.66) translate3d(0, -2.125em, 0)
		transform-origin: $text-offset-right-left
		z-index: 1

.inputWrapper
	position: relative

	&::after
		content: ''
		position: absolute
		right: 0.87em
		top: 50%
		transform: translateY(-20%) rotate(45deg)
		width: 0.4em
		height: 0.4em
		color: #979797
		color: currentColor
		border-right: 1px solid
		border-bottom: 1px solid
		transform-origin: 100% 100%

.input:disabled
	border-color: #c2c0be
	background-color: #f6f4f2
