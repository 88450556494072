
.wrapper
	display: flex
	align-items: center

	& > div
		position: static

		@media (min-width: 800px)
			position: relative

	.select.select
		position: static
		max-width: 200px

	:global
		.imageSelect__control
			display: flex
			align-items: center
			width: 44px
			height: 44px
			border-radius: 50%
			//overflow: hidden
			color: transparent
			border: 1px solid #979797
			background-color: transparent
			cursor: pointer

			&:hover
				cursor: pointer

		.imageSelect__input
			opacity: 0

		.imageSelect__clear-indicator,
		.imageSelect__dropdown-indicator
			display: none

		.imageSelect__indicator-separator
			display: none

		.imageSelect__value-container
			display: flex
			position: relative
			width: 100%
			height: 100%

		.imageSelect__multi-value__remove
			display: none

		.imageSelect__single-value,
		.imageSelect__multi-value
			position: absolute
			top: 50%
			left: 50%
			width: 30px
			height: 30px
			margin: 0
			transform: translate(-50%, -50%)
			background-color: transparent

		.imageSelect__multi-value:not(:first-child)
			opacity: 0
			visibility: hidden

		.imageSelect__multi-value__label
			padding: 0

		.imageSelect__menu
			width: 100%
			left: 0
			
			@media (min-width: 800px)
				min-width: 200px

	&.isMenuAligned_center
		:global
			.imageSelect__menu
				@media (min-width: 800px)
					left: 50%
					transform: translateX(-50%)

	&.isMenuAligned_left
		:global
			.imageSelect__menu
				@media (min-width: 800px)
					left: auto
					right: 0

	&.isMenuAligned_right
		:global
			.imageSelect__menu
				@media (min-width: 800px)
					left: 0
					right: auto

.label
	line-height: 1.2
	font-weight: 300
	margin-right: 0.5em

.multiValue
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%

.selectedOptionsPlaceholder
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	color: #000

	.optionText
		font-weight: 700
		font-size: 1.2em
		display: none

		&.isVisible
			display: block

	.optionImage,
	.optionIcon,
	.optionColor
		margin: 0 auto

.selectedOptionsCount
	&.is_visible
		&::before
			$offset: -0.0625rem
			counter-reset: optionsCount var(--ImageSelect-selectedOptionsCount)
			content: counter(optionsCount)
			position: absolute
			display: flex
			align-items: center
			justify-content: center
			top: $offset
			right: $offset
			width: calc(1.2rem + 2 * #{$offset})
			aspect-ratio: 1
			font-weight: 700
			font-size: 0.8em
			line-height: 1
			border-radius: 50%
			color: #000000
			border: 0.0625rem solid #000000
			background: #ffffff
			z-index: 1

.multiValue > .option
	visibility: hidden

.option
	display: flex
	align-items: center
	height: 100%

.optionImage,
.optionIcon,
.optionColor
	position: relative
	flex-shrink: 0
	width: 30px
	aspect-ratio: 1
	font-size: 30px
	border-radius: 50%
	margin-right: 0.5em

.optionIcon
	display: flex

.optionImage
	overflow: hidden

.optionColor
	background-color: var(--ImageSelect-option-background, #000)
