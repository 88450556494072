@use 'sass:math'

$text-offset-right-left: .875rem

.wrapper
	:global
		.MultiSelect__value-container
			padding-inline: $text-offset-right-left
			max-width: 100%

		.MultiSelect__control
			// following properties are copied from Input.module.sass
			$font-size: 1.125em
			$height: 3rem
			$border-width: 1px
			font-size: $font-size
			height: $height
			line-height: 1
			border: $border-width solid var(--global-colors-gray-500)
			background-color: #ffffff
			border-radius: 0.28em
			font-size: .875rem

		.MultiSelect__placeholder
			color: rgba(#28282a, 0.3)
			margin: 0
