@use 'common'
@use 'sass:math'

$-size: 1.93em

.wrapper
	position: absolute
	font-size: 0.6em
	border-radius: 50%
	background-color: var(--global-colors-light)
	border: .0625rem solid var(--global-colors-dark)
	width: $-size
	aspect-ratio: 1
	display: flex
	align-items: center
	justify-content: center

	&.is_variant_plain
		&.is_inverted
			color: var(--global-colors-light)
			background-color: var(--global-colors-dark)
			border: .0625rem solid var(--global-colors-dark)

	&.is_variant_primary
		color: #ffffff
		background-color: var(--global-colors-brand)
		border-color: var(--global-colors-brand)

	&.is_variant_danger
		color: #ffffff
		background-color: var(--global-colors-red-600)
		border-color: var(--global-colors-red-600)

	&.is_parentShape_unspecified
		top: -0.05em
		right: -0.75em

	&.is_parentShape_rectangular
		top: math.div($-size, -3)
		right: math.div($-size, -3)

	&.is_parentShape_circular
		bottom: calc(85% - #{math.div($-size, 2)})
		left: calc(85% - #{math.div($-size, 2)})
