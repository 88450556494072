@use 'common'
@import '@goodlok/ui/src/styles/globals.sass'
@import 'collapsible-react-component/dist/index.css'

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=50a914e5-9186-4b49-8f60-36d6a8c5e9f6")

@font-face
	font-family: common.$fontNeueVektorName
	font-weight: common.$fwNormal
	src: url("/fonts/58cec528-68e6-4864-b1a7-7dbee7d452fd.woff2") format("woff2"),url("/fonts/553a269b-03db-40d1-ab17-83eaf6362609.woff") format("woff")

@font-face
	font-family: common.$fontNeueVektorName
	font-weight: common.$fwBold
	src: url("/fonts/83b15a14-c534-4c13-9500-e6b8af6cb68f.woff2") format("woff2"),url("/fonts/56a24937-8f74-4669-a30d-034016bab543.woff") format("woff")

html,
body
	background: common.$goodlokLight
	color: common.$goodlokDark
	outline: none
	padding: 0
	margin: 0
	font-family: common.$fontNeueVektor

body
	overflow-y: scroll

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box

ul
	list-style-type: none
	padding-left: 0

input
	font-family: inherit

button
	border: 0
	padding: 0
	margin: 0
	cursor: pointer
	background: transparent
	font-family: inherit
	font-size: 100%
	line-height: 1.15
	color: inherit

button
	padding: common.$spacing*5

label
	cursor: pointer

legend
	padding: 0
	display: table

fieldset
	border: 0
	padding: 0.01em 0 0 0 // https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
	margin: 0
	min-width: 0

body:not(:-moz-handler-blocked) fieldset
	display: table-cell

// +common.debug()
