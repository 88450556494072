$border-width: 1px
$border-radius: 1rem
$border-color: #979797

.header
	margin: 0
	display: flex
	flex-direction: column

@media (min-width: 50rem)
	.header
		flex-direction: row

.header_item
	position: relative
	display: flex
	align-items: stretch
	margin: 0
	padding: 0
	cursor: pointer
	background-color: transparent
	border: none

	&:not(.is_active):hover
		background-color: rgba(0, 0, 0, 0.03)

	&.is_active
		background-color: #2a2928
		color: #ffffff

	&:first-child
		border-top-left-radius: $border-radius
		border-top-right-radius: $border-radius

	&:last-child
		border-bottom-left-radius: $border-radius
		border-bottom-right-radius: $border-radius

	&:not(:first-child)
		margin-top: calc(-1 * #{$border-width})

@media (min-width: 50rem)
	.header_item
		&:first-child
			border-top-right-radius: 0
			border-bottom-left-radius: $border-radius

		&:last-child
			border-top-right-radius: $border-radius
			border-bottom-left-radius: 0

		&:not(:first-child)
			margin-top: 0
			margin-left: calc(-1 * #{$border-width})

.header_content
	padding: 1.25em
	display: flex
	align-items: center
	border-radius: inherit
	flex-grow: 1
	line-height: 1.1
	text-transform: uppercase
	border: $border-width solid $border-color
	font-size: 1.1em

.content[open]
	margin-top: 2em

.content_summary
	display: none
