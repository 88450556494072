.wrapper
	//padding: 1em

	&.view_unwrapped
		padding: 0

.card
	width: 100%
	max-width: 1000px
	margin: 0 auto
	border: solid 1px
	background: var(--Card-backgroundColor, var(--Background-color, #FFFFFF))
	border-radius: 1em
	box-shadow: 0 0.1em 0.2em rgba(#000000, 0.1), 0 0.1em 1em rgba(#000000, 0.1)

	.view_size_full &
		max-width: none

	.view_size_small &
		max-width: 480px

	.view_hasTop &
		position: relative
		margin-top: .5em

		> .parts
			padding-top: 0.75em

	.view_hasBottom &
		position: relative
		margin-bottom: .5em

		> .parts
			padding-bottom: 0.75em

.main,
.header,
.footer
	padding: 1em

	> :first-child
		margin-top: 0

.main,
.header,
.footer,
.table
	&:not(:first-child)
		border-top: solid 1px

.footer,
.header
	display: flex
	justify-content: space-between
	align-items: baseline

	&Start, &End
		> *
			margin: 0

	&Start
		flex-grow: 1

	&End
		margin-left: auto

.topLeft,
.topRight,
.bottomLeft,
.bottomRight
	position: absolute
	height: 16px
	display: flex
	align-items: center

	&Content
		display: inline-block
		background: red
		border-radius: 2em
		background: var(--Card-backgroundColor, var(--Background-color, #FFFFFF))

.topLeft
	top: -8px
	left: 1em

.topRight
	top: -8px
	right: 1em

.bottomLeft
	bottom: -8px
	left: 1em

.bottomRight
	bottom: -8px
	right: 1em

.table
	overflow-x: auto
	-webkit-overflow-scrolling: touch

	> table
		width: 100%
		border-collapse: collapse

		> thead, > tbody, > tfoot
			> tr
				> td, > th
					text-align: left
					padding: .5em 1em

			&:not(:last-child)
				> tr
					> td, > th
						border-bottom: solid 1px

		> thead, > tfoot
			> tr
				> td, > th
					background: rgba(#000, .1)

.form,
.fieldset
	display: block
	margin: 0
	padding: 0
	border: 0
